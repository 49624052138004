import { useQuery } from '@tanstack/react-query'
import { notification } from 'antd'
import { createContext } from 'react'

import { httpClient } from 'core/http'

export interface AppContextValue {
    notify: ReturnType<typeof notification.useNotification>[0]
    user: User | undefined
}

export const AppContext = createContext<AppContextValue | null>(null)

export type AppProviderProps = {
    children: React.ReactNode
}

export type User = {
    id: number
    name: string
    email: string
    emailVerifiedAt: string | null
    twoFactorConfirmedAt: string | null
    currentTeamId: number | null
    createdAt: string
    updatedAt: string
    profilePhotoUrl: string
}

export const AppProvider = ({ children }: AppProviderProps) => {
    const [notify, notifyContext] = notification.useNotification()
    const { apiUrl } = window.pageModel

    const {
        data: userData,
        isLoading: userLoading,
        isError: userError,
    } = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            try {
                await httpClient.get(`${apiUrl}/sanctum/csrf-cookie`)
                return await httpClient.get<User>(`${apiUrl}/api/user`)
            } catch (err) {
                await httpClient.post(`${apiUrl}/api/login`, {
                    body: {
                        email: 'test@example.com',
                        password: 'password',
                    },
                })

                return httpClient.get<User>(`${apiUrl}/api/user`)
            }
        },
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    })

    return (
        <AppContext.Provider
            value={{
                notify,
                user: userData,
            }}
        >
            {notifyContext}
            {userData ? children : null}
        </AppContext.Provider>
    )
}
