import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import getThumbnail from 'browser-thumbnail-generator'

import { httpClient } from 'core/http'
import {
    Campaign,
    CampaignRequest,
    GetCampaignResponse,
    GetCampaignsResponse,
} from 'types/Campaign'
import { FileType } from 'types/Media'

type UseCampaignsOptions = {
    id?: string
    brandId?: string
}

export const useCampaigns = ({ id, brandId }: UseCampaignsOptions = {}) => {
    const queryClient = useQueryClient()
    const { apiUrl } = window.pageModel

    const {
        data: campaignsData,
        isLoading: campaignsLoading,
        refetch: refetchCampaigns,
    } = useQuery({
        queryKey: ['campaigns', brandId],
        queryFn: () => {
            return httpClient.get<GetCampaignsResponse>(
                `${apiUrl}/api/brands/${brandId}/campaigns`
            )
        },
        enabled: !!brandId,
    })
    const campaigns = campaignsData?.data ?? []

    const { mutateAsync: mutateCreateCampaign } = useMutation({
        mutationFn: async (body: Partial<CampaignRequest>) => {
            const url = brandId
                ? `${apiUrl}/api/brands/${brandId}/campaigns`
                : `${apiUrl}/api/campaigns`
            return httpClient.post<GetCampaignResponse>(url, {
                body,
                isFormData: true,
            })
        },
    })
    const createCampaign = async (media: FileType) => {
        const { thumbnail } = await getThumbnail({
            file: media,
            width: 500,
            height: 500,
            maintainAspectRatio: true,
        })
        const thumbnailFile = new File([thumbnail], `foo-${Date.now()}-thumb.jpg`, {
            lastModified: Date.now(),
            type: 'image/jpeg',
        })
        const { data } = await mutateCreateCampaign({
            title: media.name,
            media,
            thumbnail: thumbnailFile,
            launchedAt: new Date().toISOString(),
            medium: 'tv',
        })

        return data
    }

    const { mutateAsync: mutateDeleteCampaign } = useMutation({
        mutationFn: async (id: number) => {
            return httpClient.delete<Campaign>(`${apiUrl}/api/campaigns/${id}`)
        },
    })
    const deleteCampaign = async (id: number) => {
        await mutateDeleteCampaign(id)
        await queryClient.invalidateQueries({
            queryKey: ['campaigns', brandId],
            exact: true,
        })
    }

    const { mutateAsync: mutateUpdateCampaign, isPending: updateCampaignPending } =
        useMutation({
            mutationFn: (campaign: Partial<Campaign>) => {
                return httpClient.patch<Campaign>(
                    `${apiUrl}/api/campaigns/${campaign.id}`,
                    {
                        body: campaign,
                    }
                )
            },
        })

    return {
        campaigns,
        refetchCampaigns,
        campaignsLoading,
        createCampaign,
        deleteCampaign,
        updateCampaign: mutateUpdateCampaign,
        updateCampaignPending,
    }
}
