import { Col, Row } from 'antd'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { useRouteParams } from 'core/hooks'
import PlusIcon from 'icons/plus.svg?react'
import { paths } from 'router/routes'
import { Brand } from 'types/Brand'
import { Loader } from 'ui/Loader'

import styles from './BrandList.module.scss'

type BrandListProps = {
    brands: Brand[]
    isLoading: boolean
    isCompetition?: boolean
    size?: 'small' | 'large'
    className?: string
}

export const BrandList = ({
    brands,
    isLoading,
    isCompetition,
    size = 'large',
    className,
}: BrandListProps) => {
    const [currentBrandId] = useRouteParams('brandId')

    return (
        <Row gutter={[24, 24]} className={cx(styles.brands, className)}>
            {isLoading && (
                <Col
                    flex={size === 'large' ? '180px' : '64px'}
                    className={cx(styles.brand, styles['brand--add'])}
                >
                    <div
                        className={cx(
                            styles.brand__logo,
                            styles['brand__logo--add'],
                            styles[`brand__logo--${size}`]
                        )}
                    >
                        <Loader size={size === 'large' ? 'medium' : 'small'} />
                    </div>
                </Col>
            )}
            {brands.map((brand) => {
                return (
                    <Col
                        flex={size === 'large' ? '180px' : '64px'}
                        key={brand.id}
                        className={styles.brand}
                    >
                        <div
                            className={cx(
                                styles.brand__logo,
                                styles[`brand__logo--${size}`]
                            )}
                        >
                            <img src={brand.logoUrl} alt={brand.name} />
                        </div>
                        <div className={styles.brand__name}>{brand.name}</div>
                        <Link
                            to={`/brands/${brand.id}`}
                            className={styles.brand__action}
                        />
                    </Col>
                )
            })}
            <Col
                flex={size === 'large' ? '180px' : '64px'}
                className={cx(styles.brand, styles['brand--add'])}
            >
                <div
                    className={cx(
                        styles.brand__logo,
                        styles['brand__logo--add'],
                        styles[`brand__logo--${size}`]
                    )}
                >
                    <PlusIcon />
                </div>
                <div className={styles.brand__add}>
                    {size === 'large' ? 'add a brand' : 'add'}
                </div>
                <Link
                    to={
                        isCompetition
                            ? `${paths.addBrand}?competitorId=${currentBrandId}`
                            : paths.addBrand
                    }
                    className={styles.brand__action}
                />
            </Col>
        </Row>
    )
}
