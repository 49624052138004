import Cookies from 'js-cookie'

import { BASE_PATH } from '../../constants'
import { HttpError } from './HttpError'

interface RequestOptions extends Omit<RequestInit, 'body'> {
    method?: string
    basePath?: string
    body?: unknown
    isFormData?: boolean
}

const fetchRequest = async <TResponse>(
    url: string,
    { body, basePath, isFormData, method = 'GET', ...options }: RequestOptions
): Promise<TResponse> => {
    let data
    let response

    const defaultHeaders: Record<string, string> = isFormData
        ? {
              Accept: 'application/json',
          }
        : {
              'Content-Type': 'application/json',
              Accept: 'application/json',
          }
    const formData = new FormData()
    const formDataMethod = method === 'GET' ? 'GET' : 'POST'

    if (isFormData && body) {
        formData.append('_method', method)
        Object.entries(body).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((item) => formData.append(`${key}[]`, item))
            } else {
                formData.append(key, value)
            }
        })
    }

    try {
        response = await fetch(`${basePath ?? BASE_PATH}${url}`, {
            ...options,
            method: isFormData ? formDataMethod : method,
            credentials: 'include',
            body: body ? (isFormData ? formData : JSON.stringify(body)) : undefined,
            headers: {
                ...defaultHeaders,
                ...options.headers,
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') ?? '',
            },
        })
    } catch (error) {
        console.error(error)
        throw new Error(`Network error: ${error}`)
    }

    const responseText = await response.text()

    try {
        data = JSON.parse(responseText)
    } catch (error) {
        // NOOP
    }

    // if (response.status === 401) {
    //     const redirectUrl = data.redirectUrl.replace(/from=.*/g, encodeURIComponent(`from=${window.location.href}`));
    //     window.open(redirectUrl, '_self');
    // }

    if (!response.ok) {
        throw new HttpError({
            status: response.status,
            message: response.statusText,
        })
    }

    return (data || responseText) as TResponse
}

export const httpClient = {
    get: async <TResponse>(url: string, options?: RequestOptions) =>
        fetchRequest<TResponse>(url, { method: 'GET', ...options }),
    post: async <TResponse>(url: string, options?: RequestOptions) =>
        fetchRequest<TResponse>(url, { method: 'POST', ...options }),
    put: async <TResponse>(url: string, options?: RequestOptions) =>
        fetchRequest<TResponse>(url, { method: 'PUT', ...options }),
    patch: async <TResponse>(url: string, options?: RequestOptions) =>
        fetchRequest<TResponse>(url, { method: 'PATCH', ...options }),
    delete: async <TResponse>(url: string, options?: RequestOptions) =>
        fetchRequest<TResponse>(url, { method: 'DELETE', ...options }),
}
