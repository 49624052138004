export const initialiseMsw = async () => {
    if (window.pageModel.enableBrowserMocks !== 'true') {
        return
    }

    const { worker } = await import('../../test-utils/mocks/browser')

    return worker.start({
        onUnhandledRequest: 'bypass',
    })
}
