import { Navigate, RouteObject } from 'react-router-dom'

import { App } from 'src/App'
import { Layout } from 'src/ui/Layout'
import { BrandPage } from 'pages/BrandPage'
import { BrandsPage } from 'pages/BrandsPage'
import { CreateBrandPage } from 'pages/CreateBrandPage'
import { CreativeMediaPage } from 'pages/CreativeMediaPage'
import { EditBrandPage } from 'pages/EditBrandPage'
import { AccountNavigationBar } from 'ui/AccountNavigationBar'
import { BrandNavigationBar } from 'ui/BrandNavigationBar'

export const paths = {
    homepage: '/',
    brands: '/brands',
    brand: '/brands/:brandId',
    addBrand: '/brands/add',
    editBrand: '/brands/:brandId/edit',
    creativeMedia: '/brands/:brandId/creative-media',
}

export const routes: RouteObject[] = [
    {
        path: '*',
        element: <Navigate to={paths.brands} replace />,
    },
    {
        element: <App />,
        children: [
            {
                element: <Layout navigationBar={<AccountNavigationBar />} />,
                children: [
                    {
                        path: paths.brands,
                        element: <BrandsPage />,
                    },
                    {
                        path: paths.addBrand,
                        element: <CreateBrandPage />,
                    },
                ],
            },
            {
                element: <Layout navigationBar={<BrandNavigationBar />} />,
                children: [
                    {
                        path: paths.brand,
                        element: <BrandPage />,
                    },
                    {
                        path: paths.editBrand,
                        element: <EditBrandPage />,
                    },
                    {
                        path: paths.creativeMedia,
                        element: <CreativeMediaPage />,
                    },
                ],
            },
        ],
    },
]
