import { Dropdown, Menu, MenuProps, Modal, Typography } from 'antd'
import cx from 'classnames'
import { useState } from 'react'
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import { useAppContext, useRouteParams } from 'core/hooks'
import IconBin from 'icons/bin.svg?react'
import IconEdit from 'icons/edit.svg?react'
import { useBrands } from 'pages/BrandPage'
import { paths, routes } from 'router/routes'
import { MenuInfo } from 'types/App'
import { Loader } from 'ui/Loader'

import styles from './BrandNavigationBar.module.scss'

const navigationMenuItems: MenuProps['items'] = [
    {
        label: 'Big Picture',
        key: '/big-picture',
    },
    {
        label: 'Monthly',
        key: '/monthly',
        children: [
            {
                type: 'group',
                label: 'Item 1',
                children: [
                    {
                        label: 'Option 1',
                        key: 'setting:1',
                    },
                    {
                        label: 'Option 2',
                        key: 'setting:2',
                    },
                ],
            },
            {
                type: 'group',
                label: 'Item 2',
                children: [
                    {
                        label: 'Option 3',
                        key: 'setting:3',
                    },
                    {
                        label: 'Option 4',
                        key: 'setting:4',
                    },
                ],
            },
        ],
    },
]

export const BrandNavigationBar = () => {
    const [currentBrandId] = useRouteParams('brandId')
    const navigate = useNavigate()
    const location = useLocation()
    const matches = matchRoutes(routes, location)
    const { notify } = useAppContext()
    const selectedMenuKeys = matches?.map((match) => match.route.path || '') ?? []
    const isEditPage = matches?.map((match) => match.route.path).includes(paths.editBrand)

    const { brands, brandsLoading, deleteBrand, deleteBrandPending } = useBrands({
        id: currentBrandId,
    })
    const currentBrand = brands.find((brand) => brand.id === parseInt(currentBrandId))
    const brandDropdownItems: MenuProps['items'] = brands
        .filter((brand) => {
            return brand.id !== parseInt(currentBrandId)
        })
        .map((brand) => {
            return {
                label: (
                    <Link
                        to={`/brands/${brand.id}`}
                        className={styles.brandDropdown__link}
                    >
                        <div
                            className={cx(
                                styles.brandDropdown__logo,
                                styles['brandDropdown__logo--selectable']
                            )}
                        >
                            <img src={brand.logoUrl} alt={brand.name} />
                        </div>
                        <h2
                            className={cx(
                                styles.brandDropdown__name,
                                styles['brandDropdown__name--selectable']
                            )}
                        >
                            {brand.name}
                        </h2>
                    </Link>
                ),
                key: `/brands/${brand.id}`,
            }
        })

    const handleMenuClick = ({ key, keyPath }: MenuInfo) => {
        navigate(key)
        console.log({ matches })
    }

    const handleDeleteConfirm = async () => {
        await deleteBrand()
        setDeleteBrandOpen(false)
        notify.success({
            message: 'Brand deleted successfully',
        })
        navigate(paths.brands)
    }

    const [deleteBrandOpen, setDeleteBrandOpen] = useState(false)
    const handleDeleteClick = () => {
        setDeleteBrandOpen(true)
    }
    const handleBrandCancel = () => {
        setDeleteBrandOpen(false)
    }

    return (
        <>
            <div className={styles.brandNavigation}>
                {brandsLoading ? (
                    <Loader className={styles.brandNavigation__loader} />
                ) : (
                    <>
                        <Dropdown
                            menu={{ items: brandDropdownItems }}
                            className={styles.brandDropdown}
                        >
                            <div className={styles.brandDropdown__button}>
                                <Link
                                    to={`/brands/${currentBrandId}`}
                                    className={styles.brandDropdown__current}
                                >
                                    <div className={styles.brandDropdown__logo}>
                                        <img
                                            src={currentBrand?.logoUrl}
                                            alt={currentBrand?.name}
                                        />
                                    </div>
                                    <h2 className={styles.brandDropdown__name}>
                                        {currentBrand?.name}
                                    </h2>
                                </Link>
                            </div>
                        </Dropdown>
                        <Menu
                            onClick={handleMenuClick}
                            selectedKeys={selectedMenuKeys}
                            mode="horizontal"
                            items={navigationMenuItems}
                            className={styles.brandNavigation__menu}
                        />
                    </>
                )}
                <div className={styles.brandNavigation__actions}>
                    {!isEditPage && (
                        <Link
                            to={`/brands/${currentBrandId}/edit`}
                            className={cx(styles.brandNavigation__action, 'linkButton')}
                        >
                            <IconEdit />
                            <span>edit</span>
                        </Link>
                    )}
                    <Typography.Link
                        onClick={handleDeleteClick}
                        className={cx(styles.brandNavigation__action, 'linkButton')}
                    >
                        <IconBin />
                        <span>delete this brand</span>
                    </Typography.Link>
                </div>
            </div>
            <Modal
                open={deleteBrandOpen}
                onOk={handleDeleteConfirm}
                confirmLoading={deleteBrandPending}
                onCancel={handleBrandCancel}
                okText="Delete"
                title="Are you sure you want to delete this brand?"
                okButtonProps={{ danger: true }}
                maskClosable={false}
            />
        </>
    )
}
