import Icon from '@ant-design/icons'
import {
    Button,
    Col,
    Flex,
    Form,
    FormInstance,
    Input,
    Row,
    Tooltip,
    Typography,
} from 'antd'
import cx from 'classnames'
import { useRef, useState } from 'react'

import { useRouteParams } from 'core/hooks'
import IconEdit from 'icons/edit.svg?react'
import IconInfo from 'icons/info.svg?react'
import IconPlay from 'icons/play.svg?react'
import IconWarning from 'icons/warning.svg?react'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { CampaignPreview } from 'pages/CreativeMediaPage/components/CampaignPreview'
import { Campaign } from 'types/Campaign'

import styles from './MediaEditor.module.scss'

type FieldType = {
    description: string
}

type MediaEditorProps = {
    campaign: Campaign
    onSaveClick: () => void
}

export const MediaEditor = ({ campaign, onSaveClick }: MediaEditorProps) => {
    const [currentBrandId] = useRouteParams('brandId')
    const [isEditing, setIsEditing] = useState(false)
    const handleEditClick = () => {
        setIsEditing(!isEditing)
    }

    const formRef = useRef<FormInstance | null>(null)

    const { updateCampaign, updateCampaignPending } = useCampaigns({
        brandId: currentBrandId,
    })
    const handleFormFinish = async ({ description }: { description: string }) => {
        setIsEditing(false)
        await updateCampaign({
            id: campaign.id,
            description,
        })
        onSaveClick()
    }

    return (
        <Form<{ description: string }>
            variant="filled"
            layout="vertical"
            size="large"
            initialValues={{ description: campaign.description }}
            ref={formRef}
            disabled={!isEditing || updateCampaignPending}
            onFinish={handleFormFinish}
        >
            <Row gutter={24}>
                <Col span={8}>
                    <Flex gap={16} vertical>
                        <Typography.Text strong>tv commercial</Typography.Text>
                        <div className={styles.media__thumbnail}>
                            <CampaignPreview
                                title={campaign.title}
                                srcUrl={campaign.mediaUrl}
                                thumbnailUrl={campaign.thumbnailUrl}
                            />
                            <Flex align="center" gap={8} className={styles.media__play}>
                                <IconPlay />
                                <span>play</span>
                            </Flex>
                        </div>
                        <Flex gap={8} vertical>
                            <Typography.Text strong>Title</Typography.Text>
                            <Typography.Text>{campaign.title}</Typography.Text>
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={updateCampaignPending}
                                block
                            >
                                save changes
                            </Button>
                        </Flex>
                    </Flex>
                </Col>
                <Col span={16}>
                    <Flex gap={16} vertical className={styles.media__transcript}>
                        <Flex align="center" justify="space-between">
                            <Flex align="center" gap={8}>
                                <Typography.Text strong>
                                    transcript and description
                                </Typography.Text>
                                <Tooltip title="Prompt text">
                                    <IconInfo className="color--info" />
                                </Tooltip>
                                <Tooltip title="Prompt text">
                                    <IconWarning className="color--warning" />
                                </Tooltip>
                            </Flex>
                            {!isEditing && (
                                <Button
                                    type="link"
                                    disabled={false}
                                    icon={<Icon component={IconEdit} />}
                                    onClick={handleEditClick}
                                >
                                    edit
                                </Button>
                            )}
                        </Flex>
                        <div className={styles.media__description}>
                            <Form.Item<FieldType> name="description">
                                <Input.TextArea
                                    className={cx(styles.media__descriptionInput)}
                                    autoFocus
                                />
                            </Form.Item>
                        </div>
                    </Flex>
                </Col>
            </Row>
        </Form>
    )
}
