import IconBillboard from 'icons/billboard.svg?react'
import IconKeyVisual from 'icons/key-visual.svg?react'
import IconRadio from 'icons/radio.svg?react'
import IconTv from 'icons/tv.svg?react'
import { CampaignMedium } from 'types/Campaign'

import styles from './CategoryIcon.module.scss'

const categoryIcon: Record<CampaignMedium, React.ReactNode> = {
    tv: <IconTv />,
    billboard: <IconBillboard />,
    radio: <IconRadio />,
    print: <IconKeyVisual />,
}

type CategoryIconProps = {
    category: CampaignMedium | null
}

export const CategoryIcon = ({ category }: CategoryIconProps) => {
    return (
        <div className={styles.categoryIcon}>
            {category ? categoryIcon[category] : null}
        </div>
    )
}
