import { FormInstance } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { useRouteParams } from 'core/hooks'
import { useBrands } from 'pages/BrandPage'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { BrandForm } from 'pages/EditBrandPage/components/BrandForm'
import { paths } from 'router/routes'
import { Brand } from 'types/Brand'
import { Campaign, PendingCampaign } from 'types/Campaign'
import { FileType } from 'types/Media'
import { PageTitle } from 'ui/PageTitle'

export const EditBrandPage = () => {
    const [currentBrandId] = useRouteParams('brandId')

    const { brand, brandLoading, editBrand, editBrandPending } = useBrands({
        id: currentBrandId,
    })

    const {
        campaigns: existingCampaigns,
        campaignsLoading: existingCampaignsLoading,
        createCampaign,
    } = useCampaigns({ brandId: currentBrandId })

    const [campaigns, setCampaigns] =
        useState<Array<PendingCampaign | Campaign>>(existingCampaigns)
    useEffect(() => {
        setCampaigns(existingCampaigns)
    }, [existingCampaigns.length]) // eslint-disable-line react-hooks/exhaustive-deps

    const formRef = useRef<FormInstance | null>(null)

    const handleMediaBeforeUpload = async (file: FileType) => {
        const fileId = Date.now()
        setCampaigns((prevCampaigns) => [
            {
                id: fileId,
                loading: true,
                title: file.name,
                launchedAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
            },
            ...prevCampaigns,
        ])

        const campaign = await createCampaign(file)

        setCampaigns((prevCampaigns) => {
            return [
                campaign,
                ...prevCampaigns.filter((campaign) => campaign.id !== fileId),
            ]
        })

        return false
    }

    const handleCampaignDelete = (deletedCampaign: Campaign) => {
        setCampaigns((prevCampaigns) =>
            prevCampaigns.filter(
                (previousCampaign) => previousCampaign.id !== deletedCampaign.id
            )
        )
    }

    const handleCampaignChange = (campaign: Partial<Campaign>) => {
        setCampaigns((prevCampaigns) => {
            return prevCampaigns.map((prevCampaign) => {
                return prevCampaign.id === campaign.id
                    ? { ...prevCampaign, ...campaign }
                    : prevCampaign
            })
        })
    }

    const handleFormFinish = async (brand: Brand) => {
        await editBrand({
            ...brand,
            campaignIds: campaigns.map((campaign) => campaign.id),
        })
    }

    return (
        <>
            <PageTitle
                title="brand overview - edit brand"
                heading="Edit this brand"
                backLink={paths.brand.replace(':brandId', String(currentBrandId))}
            />
            <div className="page">
                <BrandForm
                    formRef={formRef}
                    brand={brand}
                    campaigns={campaigns}
                    isPending={editBrandPending}
                    brandLoading={brandLoading}
                    campaignsLoading={existingCampaignsLoading}
                    submitLabel="update brand"
                    onFinish={handleFormFinish}
                    onMediaBeforeUpload={handleMediaBeforeUpload}
                    onCampaignChange={handleCampaignChange}
                    onCampaignDelete={handleCampaignDelete}
                />
            </div>
        </>
    )
}
