export class HttpError extends Error {
    message: string;
    status: number;

    constructor({ message, status }: { message: string; status: number; path?: string; rootCause?: string }) {
        super();
        this.name = 'HttpError';
        this.message = message;
        this.status = status;
    }
}
