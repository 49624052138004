/// <reference types="vite-plugin-svgr/client" />

import { DevSupport } from '@react-buddy/ide-toolbox'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { initialiseMsw } from 'core/http/initialiseMsw'

import { ComponentPreviews, useInitial } from './dev'
import { router } from './router'
;(async () => {
    try {
        await initialiseMsw()

        ReactDOM.createRoot(document.getElementById('root')!).render(
            <React.StrictMode>
                <DevSupport
                    ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
                >
                    <RouterProvider router={router} />
                </DevSupport>
            </React.StrictMode>
        )
    } catch (err) {
        console.error(err)
    }
})()
