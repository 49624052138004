import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from 'core/hooks'
import { httpClient } from 'core/http'
import { paths } from 'router/routes'
import {
    BrandRequest,
    GetBrandResponse,
    GetBrandsResponse,
    GetCompetitorsResponse,
} from 'types/Brand'
import { Campaign } from 'types/Campaign'

type UseBrandsOptions = {
    id?: string
    competitorId?: string | null
}

export const useBrands = ({ id }: UseBrandsOptions = {}) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { notify } = useAppContext()
    const { apiUrl } = window.pageModel

    const {
        data: brandsData,
        isLoading: brandsLoading,
        isError: brandsError,
        refetch: refetchBrands,
    } = useQuery({
        queryKey: ['brands'],
        queryFn: () => {
            return httpClient.get<GetBrandsResponse>(`${apiUrl}/api/brands`)
        },
    })
    const brands = brandsData?.data ?? []

    const { data: brandData, isLoading: brandLoading } = useQuery({
        queryKey: ['brands', id],
        queryFn: () => {
            return httpClient.get<GetBrandResponse>(`${apiUrl}/api/brands/${id}`)
        },
        enabled: !!id,
    })
    const brand = brandData?.data

    const { mutateAsync: mutateCreateBrand, isPending: pendingCreateBrand } = useMutation(
        {
            mutationFn: (body: BrandRequest) => {
                return httpClient.post<GetBrandResponse>(`${apiUrl}/api/brands`, {
                    body,
                    isFormData: true,
                })
            },
        }
    )
    const createBrand = async (brand: BrandRequest) => {
        const {
            data: { id },
        } = await mutateCreateBrand(brand)
        navigate(paths.brand.replace(':brandId', String(id)))
        notify.success({
            message: 'Brand created successfully',
        })
    }

    const { mutateAsync: mutateEditBrand, isPending: editBrandPending } = useMutation({
        mutationFn: (body: BrandRequest) => {
            return httpClient.patch<GetBrandResponse>(`${apiUrl}/api/brands/${id}`, {
                body,
                isFormData: true,
            })
        },
    })
    const editBrand = async (brand: BrandRequest) => {
        await mutateEditBrand(brand)
        navigate(paths.brand.replace(':brandId', id || ''))
        notify.success({
            message: 'Brand updated successfully',
        })
    }

    const { mutateAsync: mutateDeleteBrand, isPending: deleteBrandPending } = useMutation(
        {
            mutationFn: async () => {
                return httpClient.delete<Campaign>(`${apiUrl}/api/brands/${id}`)
            },
        }
    )
    const deleteBrand = async () => {
        await mutateDeleteBrand()
        await queryClient.invalidateQueries({ queryKey: ['brands'], exact: true })
    }

    const { data: competitorsData, isLoading: competitorsLoading } = useQuery({
        queryKey: ['competitors', id],
        queryFn: () => {
            return httpClient.get<GetCompetitorsResponse>(
                `${apiUrl}/api/brands/${id}/competitors`
            )
        },
        enabled: !!id,
    })
    const competitors = competitorsData?.data ?? []

    return {
        brands,
        brandsLoading,
        brandsError,
        refetchBrands,
        brand,
        brandLoading,
        createBrand,
        pendingCreateBrand,
        editBrand,
        editBrandPending,
        deleteBrand,
        deleteBrandPending,
        competitors,
        competitorsLoading,
    }
}
