import { Flex } from 'antd'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CampaignMediums } from 'src/ui/CampaignMediums'
import { useRouteParams } from 'core/hooks'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { MediaEditor } from 'pages/CreativeMediaPage/components/MediaEditor'
import { MediaList } from 'pages/CreativeMediaPage/components/MediaList'
import { paths } from 'router/routes'
import { Campaign, CampaignMedium, PendingCampaign } from 'types/Campaign'
import { PageTitle } from 'ui/PageTitle'

import styles from './CreativeMediaPage.module.scss'

export const CreativeMediaPage = () => {
    const [currentBrandId] = useRouteParams('brandId')
    const [searchParams, setSearchParams] = useSearchParams()
    const currentSearchParam = searchParams.get('selectedMedium') as CampaignMedium | null
    const [activeMedium, setActiveMedium] = useState<CampaignMedium>(
        currentSearchParam || 'tv'
    )
    const handleMediumClick = (medium: CampaignMedium) => {
        setSelectedCampaign(null)
        setActiveMedium(medium)
        setSearchParams({ selectedMedium: medium })
    }

    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null)
    const handleCampaignClick = (campaign: Campaign | PendingCampaign) => {
        if ('thumbnailUrl' in campaign) setSelectedCampaign(campaign)
    }

    const { campaigns, campaignsLoading, refetchCampaigns } = useCampaigns({
        brandId: currentBrandId,
    })
    const filteredCampaigns = campaigns.filter((campaign) => {
        return campaign.medium === activeMedium
    })
    const handleSaveClick = () => {
        void refetchCampaigns()
    }
    const handleCampaignDelete = (campaign: Campaign) => {
        if (selectedCampaign?.id === campaign.id) setSelectedCampaign(null)
        void refetchCampaigns()
    }
    const handleCampaignChange = () => {
        void refetchCampaigns()
    }

    return (
        <>
            <PageTitle
                title="brand overview - creative media"
                backLink={paths.brand.replace(':brandId', String(currentBrandId))}
                heading="Creative media"
            />
            <div className="page">
                <Flex vertical gap="large">
                    <CampaignMediums
                        campaigns={campaigns}
                        isLoading={campaignsLoading}
                        onMediumClick={handleMediumClick}
                        activeMedium={activeMedium}
                    />
                    {selectedCampaign && (
                        <MediaEditor
                            key={selectedCampaign.id}
                            campaign={selectedCampaign}
                            onSaveClick={handleSaveClick}
                        />
                    )}
                    <MediaList
                        campaigns={filteredCampaigns}
                        isLoading={campaignsLoading}
                        onCampaignClick={handleCampaignClick}
                        onCampaignDelete={handleCampaignDelete}
                        onCampaignChange={handleCampaignChange}
                    />
                </Flex>
            </div>
        </>
    )
}
