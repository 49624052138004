export const industries: Record<string, string>[] = [
    {
        key: 'fabric_detergents',
        label: 'Fabric Detergents',
    },
    {
        key: 'automotive',
        label: 'Automotive',
    },
    {
        key: 'fashion',
        label: 'Fashion',
    },
    {
        key: 'food',
        label: 'Food',
    },
    {
        key: 'technology',
        label: 'Technology',
    },
]
