import {
    Button,
    Col,
    Divider,
    Flex,
    Form,
    FormInstance,
    Input,
    Row,
    Select,
    Tooltip,
    Typography,
    Upload,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import { industries } from 'constants/industries'
import IconInfo from 'icons/info.svg?react'
import IconPlus from 'icons/plus.svg?react'
import IconWarning from 'icons/warning.svg?react'
import { MediaList } from 'pages/CreativeMediaPage/components/MediaList'
import { Brand, Industry } from 'types/Brand'
import { Campaign, PendingCampaign } from 'types/Campaign'
import { FileType } from 'types/Media'
import { Loader } from 'ui/Loader'

import styles from './BrandForm.module.scss'

type FieldType = {
    logo: File
    name: string
    industry: Industry
    description: string
    essence: string
    slogan: string
}

export type BrandFormProps = {
    formRef: React.MutableRefObject<FormInstance | null>
    brand?: Brand
    campaigns: Array<Campaign | PendingCampaign>
    // logo: FileType | null
    brandLoading?: boolean
    campaignsLoading?: boolean
    isPending: boolean
    submitLabel: string
    onFinish: (brand: Brand) => Promise<void>
    // onLogoBeforeUpload: (file: FileType) => boolean
    onMediaBeforeUpload: (file: FileType) => Promise<boolean>
    onCampaignChange: (campaign: Partial<Campaign>) => void
    onCampaignDelete: (campaign: Campaign) => void
}

export const BrandForm = ({
    formRef,
    brand,
    campaigns,
    brandLoading,
    campaignsLoading,
    isPending,
    submitLabel,
    onFinish,
    onMediaBeforeUpload,
    onCampaignChange,
    onCampaignDelete,
}: BrandFormProps) => {
    const [logo, setLogo] = useState<FileType | null>(null)
    const handleLogoBeforeUpload = (file: FileType) => {
        setLogo(file)
        formRef.current?.setFieldsValue({ logo: file })

        return false
    }
    const base64Logo = logo ? URL.createObjectURL(logo) : null
    const displayLogo = base64Logo || brand?.logoUrl

    useEffect(() => {
        if (brand?.id) formRef.current?.setFieldsValue(brand)
    }, [brand?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form<Brand>
            variant="filled"
            layout="vertical"
            size="large"
            initialValues={brand}
            ref={formRef}
            disabled={brandLoading || isPending}
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Flex gap={24}>
                        <Form.Item<FieldType> name="logo" label="Brand picture / logo">
                            <ImgCrop rotationSlider>
                                <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    className={cx(styles.logoInput, {
                                        [styles['logoInput--empty']]: !displayLogo,
                                    })}
                                    // beforeUpload={onLogoBeforeUpload}
                                    beforeUpload={handleLogoBeforeUpload}
                                >
                                    {displayLogo ? (
                                        <Loader spinning={brandLoading}>
                                            <img
                                                src={displayLogo}
                                                alt={`Upload new logo for ${brand?.name}`}
                                            />
                                        </Loader>
                                    ) : (
                                        <Flex
                                            gap={16}
                                            justify="center"
                                            align="center"
                                            vertical
                                        >
                                            <IconPlus
                                                className={styles.logoInput__icon}
                                            />
                                            <Flex
                                                justify="center"
                                                align="center"
                                                vertical
                                            >
                                                <Typography.Text>
                                                    Drag and drop files, or browse from
                                                    your computer
                                                </Typography.Text>
                                                <Typography.Text type="secondary">
                                                    min 180x180 px
                                                </Typography.Text>
                                            </Flex>
                                        </Flex>
                                    )}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                        <div className={styles.brandDetails}>
                            <Form.Item<FieldType>
                                name="name"
                                label="brand name"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item<FieldType>
                                name="industry"
                                label="select an industry"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select>
                                    {industries.map((industry) => (
                                        <Select.Option
                                            key={industry.key}
                                            value={industry.key}
                                        >
                                            {industry.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Flex>
                </Col>
                <Col span={12}>
                    <Form.Item<FieldType> name="description" label="brand details">
                        <Input.TextArea rows={8} className={styles.description} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider className="pageDivider" />
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item<FieldType>
                        name="essence"
                        label={
                            <Flex align="center" gap={8}>
                                <Typography.Text>brand essence</Typography.Text>
                                <Tooltip title="Prompt text">
                                    <IconInfo className="color--info" />
                                </Tooltip>
                                <Tooltip title="Prompt text">
                                    <IconWarning className="color--warning" />
                                </Tooltip>
                            </Flex>
                        }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="slogan"
                        label={
                            <Flex align="center" gap={8}>
                                <Typography.Text>brand essence</Typography.Text>
                                <Tooltip title="Prompt text">
                                    <IconInfo className="color--info" />
                                </Tooltip>
                                <Tooltip title="Prompt text">
                                    <IconWarning className="color--warning" />
                                </Tooltip>
                            </Flex>
                        }
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Divider className="pageDivider" />
            <Flex gap={16} vertical>
                <Flex align="center" gap={8}>
                    <Typography.Text>creative media</Typography.Text>
                    <Tooltip title="Prompt text">
                        <IconInfo className="color--info" />
                    </Tooltip>
                </Flex>
                <Row gutter={24}>
                    <Col span={8}>
                        <Upload.Dragger
                            listType="picture-card"
                            showUploadList={false}
                            className={styles.mediaInput}
                            beforeUpload={onMediaBeforeUpload}
                            multiple
                        >
                            <Flex gap={16} justify="center" align="center" vertical>
                                <IconPlus className={styles.mediaInput__icon} />
                                <Typography.Text>
                                    Drag and drop files, or browse from your computer
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                    Suported files: mp3, mp4, avi, jpeg, png. Maximum size
                                    xx mb
                                </Typography.Text>
                                <Typography.Text>or</Typography.Text>
                                <Input
                                    variant="outlined"
                                    placeholder="paste a link"
                                    className={styles.mediaInput__link}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            </Flex>
                        </Upload.Dragger>
                    </Col>
                    <Col span={16}>
                        <MediaList
                            campaigns={campaigns}
                            isLoading={campaignsLoading}
                            onCampaignDelete={onCampaignDelete}
                            onCampaignChange={onCampaignChange}
                            className={styles.mediaList}
                        />
                    </Col>
                </Row>
                <Flex gap={16} align="center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isPending}
                    >
                        {submitLabel}
                    </Button>
                    <Typography.Text>
                        The fields marked with * are mandatory and essential for having a
                        correct evaluation based on a chronological order of the creative
                        materials that will be a analyzed.{' '}
                    </Typography.Text>
                </Flex>
            </Flex>
        </Form>
    )
}
