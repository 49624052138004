import { Card, Flex, Image } from 'antd'

import IconPlay from 'icons/play.svg?react'

import styles from './CampaignPreview.module.scss'

type CampaignPreviewProps = {
    thumbnailUrl: string
    srcUrl: string
    title: string
    mask?: boolean
}

export const CampaignPreview = ({
    thumbnailUrl,
    srcUrl,
    title,
    mask,
}: CampaignPreviewProps) => {
    const isVideo =
        srcUrl.endsWith('.mp4') || srcUrl.endsWith('.webm') || srcUrl.endsWith('.mov')

    return (
        <Image
            src={thumbnailUrl}
            alt={`Play ${title}`}
            preview={{
                imageRender: () => (
                    <Card className={styles.preview}>
                        {isVideo ? (
                            <video muted width="100%" controls src={srcUrl} />
                        ) : (
                            <img src={srcUrl} alt={title} />
                        )}
                    </Card>
                ),
                toolbarRender: () => null,
                mask: mask ? (
                    <Flex gap={4} align="center" className={styles.mask} vertical>
                        <IconPlay />
                        <span>play</span>
                    </Flex>
                ) : null,
            }}
        />
    )
}
