import Icon from '@ant-design/icons'
import { Button, Divider, Flex, Skeleton, Space, Tooltip, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { CampaignMediums } from 'src/ui/CampaignMediums'
import { industries } from 'constants/industries'
import { useRouteParams } from 'core/hooks'
import IconInfo from 'icons/info.svg?react'
import IconPlus from 'icons/plus.svg?react'
import IconWarning from 'icons/warning.svg?react'
import { useBrands } from 'pages/BrandPage/useBrands'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { BrandList } from 'ui/BrandList'
import { PageTitle } from 'ui/PageTitle'

import styles from './BrandPage.module.scss'

export const BrandPage = () => {
    const navigate = useNavigate()
    const [currentBrandId] = useRouteParams('brandId')

    const { brand, brandLoading, competitors, competitorsLoading } = useBrands({
        id: currentBrandId,
    })
    const industry = industries.find((item) => item.key === brand?.industry)

    const { campaigns, campaignsLoading } = useCampaigns({ brandId: currentBrandId })

    const handleCampaignMediumClick = (medium: string) => {
        navigate(`/brands/${currentBrandId}/creative-media?selectedMedium=${medium}`)
    }

    return (
        <>
            <PageTitle title="brand overview" />
            <div className="page">
                <Flex gap={16} vertical>
                    <Flex gap={8} vertical>
                        <Typography.Text strong>industry</Typography.Text>
                        <Skeleton
                            paragraph={{ rows: 1 }}
                            title={false}
                            loading={brandLoading}
                            active
                        >
                            <Typography.Text>{industry?.label}</Typography.Text>
                        </Skeleton>
                    </Flex>
                    <Flex gap={8} vertical>
                        <Typography.Text strong>details</Typography.Text>
                        <Skeleton
                            paragraph={{ rows: 2 }}
                            title={false}
                            loading={brandLoading}
                            active
                        >
                            <Typography.Text>{brand?.description}</Typography.Text>
                        </Skeleton>
                    </Flex>
                </Flex>
                <Divider className="pageDivider" />
                <Space direction="vertical" size="middle">
                    <Flex align="center" gap={8}>
                        <Typography.Text strong>competition</Typography.Text>
                        <Tooltip title="Prompt text">
                            <IconInfo className="color--info" />
                        </Tooltip>
                    </Flex>
                    <BrandList
                        brands={competitors}
                        isLoading={competitorsLoading}
                        size="small"
                        isCompetition
                    />
                </Space>
                <Divider className="pageDivider" />
                <Flex vertical gap="middle">
                    <Flex
                        align="center"
                        justify="space-between"
                        className={styles.media__heading}
                    >
                        <Flex align="center" gap={8}>
                            <Typography.Text strong>creative media</Typography.Text>
                            <Tooltip title="Prompt text">
                                <IconInfo className="color--info" />
                            </Tooltip>
                        </Flex>
                        <Link to={`/brands/${currentBrandId}/edit`}>
                            <Button
                                type="link"
                                icon={<Icon component={IconPlus} />}
                                size="small"
                            >
                                add a new media
                            </Button>
                        </Link>
                    </Flex>
                    <CampaignMediums
                        campaigns={campaigns}
                        isLoading={campaignsLoading}
                        onMediumClick={handleCampaignMediumClick}
                    />
                </Flex>
                <Divider className="pageDivider" />
                <Flex gap={16} vertical>
                    <Flex gap={8} vertical>
                        <Flex align="center" gap={8}>
                            <Typography.Text strong>brand essence</Typography.Text>
                            <Tooltip title="Prompt text">
                                <IconInfo className="color--info" />
                            </Tooltip>
                            <Tooltip title="Prompt text">
                                <IconWarning className="color--warning" />
                            </Tooltip>
                        </Flex>
                        <Skeleton
                            paragraph={{ rows: 1 }}
                            title={false}
                            loading={brandLoading}
                            active
                        >
                            <Typography.Text>{brand?.essence}</Typography.Text>
                        </Skeleton>
                    </Flex>
                    <Flex gap={8} vertical>
                        <Flex align="center" gap={8}>
                            <Typography.Text strong>slogan</Typography.Text>
                            <Tooltip title="Prompt text">
                                <IconInfo className="color--info" />
                            </Tooltip>
                            <Tooltip title="Prompt text">
                                <IconWarning className="color--warning" />
                            </Tooltip>
                        </Flex>
                        <Skeleton
                            paragraph={{ rows: 1 }}
                            title={false}
                            loading={brandLoading}
                            active
                        >
                            <Typography.Text>{brand?.slogan}</Typography.Text>
                        </Skeleton>
                    </Flex>
                </Flex>
            </div>
        </>
    )
}
