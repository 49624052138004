import Icon from '@ant-design/icons'
import { Button, DatePicker, Flex, Typography } from 'antd'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'

import { CampaignPreview } from 'src/pages/CreativeMediaPage/components/CampaignPreview'
import { useAppContext, useRouteParams } from 'core/hooks'
import IconBin from 'icons/bin.svg?react'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { Campaign, PendingCampaign } from 'types/Campaign'
import { CategoryIcon } from 'ui/CategoryIcon'
import { Loader } from 'ui/Loader'

import styles from './CampaignItem.module.scss'

type CampaignItemProps = {
    onCampaignClick: ((campaign: Campaign) => void) | undefined
    campaign: Campaign | PendingCampaign
    onPreview?: (event: React.MouseEvent<HTMLDivElement>) => void
    onDelete?: (campaign: Campaign) => void
    onLaunchedAtChange?: (date: Dayjs) => void
}

export const CampaignItem = ({
    onCampaignClick,
    campaign,
    onPreview,
    onDelete,
    onLaunchedAtChange,
}: CampaignItemProps) => {
    const [currentBrandId] = useRouteParams('brandId')
    const { notify } = useAppContext()
    const { deleteCampaign, updateCampaign } = useCampaigns({ brandId: currentBrandId })

    const isFullCampaign = 'thumbnailUrl' in campaign

    const handleDeleteClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        await deleteCampaign(campaign.id)
        if (isFullCampaign) onDelete?.(campaign)
        notify.success({
            message: 'Media deleted successfully',
            type: 'success',
        })
    }

    const handleLaunchedAtChange = async (date: Dayjs) => {
        await updateCampaign({
            id: campaign.id,
            launchedAt: date.toISOString(),
        })
        onLaunchedAtChange?.(date)
        notify.success({
            message: 'Media launch date updated successfully',
            type: 'success',
        })
    }

    const handleCampaignClick = () => {
        if (isFullCampaign) onCampaignClick?.(campaign)
    }

    return (
        <Flex
            align="center"
            gap={12}
            className={cx(styles.campaignItem, {
                [styles['campaignItem--clickable']]: !!onCampaignClick,
            })}
            onClick={handleCampaignClick}
        >
            <div className={styles.campaignItem__preview}>
                <CategoryIcon category={isFullCampaign ? campaign.medium : null} />
                {isFullCampaign ? (
                    <div className={styles.campaignItem__thumb} onClick={onPreview}>
                        <CampaignPreview
                            title={campaign.title}
                            srcUrl={campaign.mediaUrl}
                            thumbnailUrl={campaign.thumbnailUrl}
                            mask
                        />
                    </div>
                ) : (
                    <Loader className={styles.campaignItem__loader} />
                )}
            </div>
            <Typography.Text className={styles.campaignItem__title}>
                {campaign.title}
            </Typography.Text>
            <DatePicker
                value={dayjs(campaign.launchedAt)}
                onChange={handleLaunchedAtChange}
                onClick={(event) => event.stopPropagation()}
                className={styles.campaignItem__launchDate}
                allowClear={false}
                variant="outlined"
                size="large"
                disabled={!isFullCampaign}
            />
            <Button
                type="link"
                icon={<Icon component={IconBin} />}
                className={styles.campaignItem__delete}
                onClick={handleDeleteClick}
                disabled={!isFullCampaign}
            />
        </Flex>
    )
}
