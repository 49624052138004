import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider, ThemeConfig } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Outlet } from 'react-router-dom'

import { AppProvider } from 'core/AppProvider'

import './styles/globals.scss'

import variables from './styles/variables.module.scss'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const queryClient = new QueryClient()

const {
    colorPrimary,
    colorPrimaryHover,
    colorPrimaryActive,
    colorInfo,
    colorInfoBg,
    colorWarning,
    textPrimary,
    textSecondary,
} = variables
const themeConfig: ThemeConfig = {
    hashed: false,
    token: {
        colorPrimary,
        colorLink: colorPrimary,
        colorWarning,
        borderRadius: 4,
        colorText: textPrimary,
        colorTextSecondary: textSecondary,
        fontFamily: "'Raleway', sans-serif",
        colorFillTertiary: colorInfo,
    },
    components: {
        Tooltip: {
            colorBgSpotlight: colorInfoBg,
            colorTextLightSolid: 'black',
        },
        Alert: {
            colorInfo: 'black',
            colorInfoBorder: colorPrimary,
            colorInfoBg: colorInfoBg,
        },
    },
}

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ConfigProvider theme={themeConfig}>
                <AppProvider>
                    <Outlet />
                </AppProvider>
            </ConfigProvider>
        </QueryClientProvider>
    )
}
