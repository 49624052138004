import Icon from '@ant-design/icons'
import { Alert, Flex, Skeleton } from 'antd'
import cx from 'classnames'
import { Dayjs } from 'dayjs'

import IconInfo from 'icons/info.svg?react'
import { CampaignItem } from 'pages/CreativeMediaPage/components/CampaignItem'
import { Campaign, PendingCampaign } from 'types/Campaign'

import styles from './MediaList.module.scss'

type MediaListProps = {
    campaigns: Array<Campaign | PendingCampaign>
    isLoading?: boolean
    onCampaignClick?: (campaign: Campaign | PendingCampaign) => void
    onCampaignDelete?: (campaign: Campaign) => void
    onCampaignChange?: (campaign: Partial<Campaign>) => void
    className?: string
}

export const MediaList = ({
    campaigns,
    isLoading = false,
    onCampaignClick,
    onCampaignDelete,
    onCampaignChange,
    className,
}: MediaListProps) => {
    const handlePreviewClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        event.nativeEvent.stopPropagation()
    }

    const handleLaunchedAtChange = (id: number, date: Dayjs) => {
        onCampaignChange?.({ id, launchedAt: date.toISOString() })
    }

    if (!campaigns.length && !isLoading) {
        return (
            <Alert
                description="You have not uploaded any creative media of this type for this brand"
                type="info"
                icon={<Icon component={IconInfo} />}
                showIcon
            />
        )
    }

    if (isLoading) {
        return (
            <Flex align="center" gap={12} className={styles.mediaItem}>
                <Skeleton.Avatar shape="square" size="large" active />
                <Skeleton className={styles.mediaItem__title} title={false} active />
                <Skeleton.Input active />
                <Skeleton.Button size="default" shape="square" active />
            </Flex>
        )
    }

    return (
        <Flex vertical gap={4} className={cx(styles.mediaList, className)}>
            {campaigns.map((campaign) => (
                <CampaignItem
                    key={campaign.id}
                    onCampaignClick={onCampaignClick}
                    campaign={campaign}
                    onPreview={handlePreviewClick}
                    onDelete={onCampaignDelete}
                    onLaunchedAtChange={(date) =>
                        handleLaunchedAtChange(campaign.id, date)
                    }
                />
            ))}
        </Flex>
    )
}
