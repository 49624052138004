import { Col, Row } from 'antd'
import cx from 'classnames'

import { Campaign, CampaignMedium } from 'types/Campaign'
import { CategoryIcon } from 'ui/CategoryIcon'
import { Loader } from 'ui/Loader'

import styles from './CampaignMediums.module.scss'

const campaignMediums: {
    label: string
    name: CampaignMedium
    campaigns: Campaign[]
}[] = [
    {
        label: 'tv ads',
        name: 'tv',
        campaigns: [],
    },
    {
        label: 'ooh / billboard',
        name: 'billboard',
        campaigns: [],
    },
    {
        label: 'radio ads',
        name: 'radio',
        campaigns: [],
    },
    {
        label: 'kvs / prints',
        name: 'print',
        campaigns: [],
    },
]

type BrandListProps = {
    campaigns: Campaign[]
    isLoading?: boolean
    onMediumClick?: (medium: CampaignMedium) => void
    activeMedium?: CampaignMedium
    className?: string
}

export const CampaignMediums = ({
    campaigns,
    isLoading = false,
    onMediumClick,
    activeMedium,
    className,
}: BrandListProps) => {
    const mediums = campaignMediums.map((medium) => {
        const currentMediumCampaigns = campaigns.filter((campaign) => {
            return campaign.medium === medium.name
        })

        return {
            ...medium,
            campaigns: currentMediumCampaigns,
        }
    })

    return (
        <Row gutter={[24, 24]} className={className}>
            {mediums.map((medium) => {
                return (
                    <Col flex="204px" key={medium.label} className={styles.media__medium}>
                        <div
                            className={cx(styles.medium, {
                                [styles['medium--inactive']]:
                                    !!activeMedium && activeMedium !== medium.name,
                            })}
                            onClick={() => onMediumClick?.(medium.name)}
                        >
                            <CategoryIcon category={medium.name} />
                            <div className={styles.medium__label}>
                                <Loader spinning={isLoading}>
                                    <div className={styles.medium__count}>
                                        {medium.campaigns.length}
                                    </div>
                                    <div className={styles.medium__name}>
                                        {medium.label}
                                    </div>
                                </Loader>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}
