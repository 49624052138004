import { Alert, Button, Flex } from 'antd'

import { useBrands } from 'pages/BrandPage'
import { BrandList } from 'ui/BrandList'
import { PageTitle } from 'ui/PageTitle'

export const BrandsPage = () => {
    const { brands, brandsLoading, brandsError, refetchBrands } = useBrands()

    return (
        <>
            <PageTitle title="my brands" />
            <div className="page">
                <Flex gap={24} vertical>
                    {brandsError && (
                        <Alert
                            message="Failed to retrieve brands"
                            showIcon
                            description="Please try again later."
                            type="error"
                            action={
                                <Button
                                    size="small"
                                    onClick={() => refetchBrands()}
                                    danger
                                >
                                    Retry
                                </Button>
                            }
                        />
                    )}
                    <BrandList isLoading={brandsLoading} brands={brands} />
                </Flex>
            </div>
        </>
    )
}
