import { FormInstance } from 'antd'
import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useBrands } from 'pages/BrandPage'
import { useCampaigns } from 'pages/BrandPage/useCampaigns'
import { BrandForm } from 'pages/EditBrandPage/components/BrandForm'
import { paths } from 'router/routes'
import { Brand } from 'types/Brand'
import { Campaign, PendingCampaign } from 'types/Campaign'
import { FileType } from 'types/Media'
import { PageTitle } from 'ui/PageTitle'

export const CreateBrandPage = () => {
    const [searchParams] = useSearchParams()
    const competitorId = searchParams.get('competitorId')

    const { createBrand, pendingCreateBrand } = useBrands({ competitorId })

    const { createCampaign } = useCampaigns()
    const [campaigns, setCampaigns] = useState<Array<PendingCampaign | Campaign>>([])

    const formRef = useRef<FormInstance | null>(null)

    const handleMediaBeforeUpload = async (file: FileType) => {
        const fileId = Date.now()
        setCampaigns((prevCampaigns) => [
            {
                id: fileId,
                loading: true,
                title: file.name,
                launchedAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
            },
            ...prevCampaigns,
        ])

        const campaign = await createCampaign(file)

        setCampaigns((prevCampaigns) => {
            return [
                campaign,
                ...prevCampaigns.filter((campaign) => campaign.id !== fileId),
            ]
        })

        return false
    }

    const handleCampaignDelete = (deletedCampaign: Campaign) => {
        setCampaigns((prevCampaigns) =>
            prevCampaigns.filter(
                (previousCampaign) => previousCampaign.id !== deletedCampaign.id
            )
        )
    }

    const handleCampaignChange = (campaign: Partial<Campaign>) => {
        setCampaigns((prevCampaigns) => {
            return prevCampaigns.map((prevCampaign) => {
                return prevCampaign.id === campaign.id
                    ? { ...prevCampaign, ...campaign }
                    : prevCampaign
            })
        })
    }

    const handleFormFinish = async (brand: Brand) => {
        await createBrand({
            ...brand,
            campaignIds: campaigns.map((campaign) => campaign.id),
        })
    }

    return (
        <>
            <PageTitle
                title="my brands"
                heading={competitorId ? 'Add a new competition brand' : 'Add a new brand'}
                backLink={
                    competitorId
                        ? paths.brand.replace(':brandId', competitorId)
                        : paths.brands
                }
            />
            <div className="page">
                <BrandForm
                    formRef={formRef}
                    campaigns={campaigns}
                    isPending={pendingCreateBrand}
                    submitLabel="save brand"
                    onFinish={handleFormFinish}
                    onMediaBeforeUpload={handleMediaBeforeUpload}
                    onCampaignChange={handleCampaignChange}
                    onCampaignDelete={handleCampaignDelete}
                />
            </div>
        </>
    )
}
