import { Menu, MenuProps } from 'antd'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import { routes } from 'router/routes'
import { MenuInfo } from 'types/App'

import styles from './AccountNavigationBar.module.scss'

const items: MenuProps['items'] = [
    {
        label: 'my account',
        key: '/myAccount',
    },
    {
        label: 'my brands',
        key: '/brands',
    },
    {
        label: 'integrations',
        key: '/integrations',
        children: [
            {
                type: 'group',
                label: 'Item 1',
                children: [
                    {
                        label: 'Option 1',
                        key: 'setting:1',
                    },
                    {
                        label: 'Option 2',
                        key: 'setting:2',
                    },
                ],
            },
            {
                type: 'group',
                label: 'Item 2',
                children: [
                    {
                        label: 'Option 3',
                        key: 'setting:3',
                    },
                    {
                        label: 'Option 4',
                        key: 'setting:4',
                    },
                ],
            },
        ],
    },
    {
        label: 'subscription',
        key: 'subscription',
    },
]

export const AccountNavigationBar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const matches = matchRoutes(routes, location)
    const selectedMenuKeys =
        matches?.map((match) => {
            return `/${match.route.path?.split('/')[1]}` || ''
        }) ?? []

    const handleMenuClick = ({ key, keyPath }: MenuInfo) => {
        navigate(key)
        console.log({ matches })
    }

    return (
        <Menu
            onClick={handleMenuClick}
            selectedKeys={selectedMenuKeys}
            mode="horizontal"
            items={items}
            className={styles.accountNavigation}
        />
    )
}
