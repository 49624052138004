import { Button } from 'antd'
import { Link, Outlet } from 'react-router-dom'

import AccountIcon from 'icons/account.svg?react'
import LogoutIcon from 'icons/logout.svg?react'
import { paths } from 'router/routes'

import styles from './Layout.module.scss'

type LayoutProps = {
    navigationBar: React.ReactNode
}

export const Layout = ({ navigationBar }: LayoutProps) => {
    return (
        <>
            {navigationBar}
            <div className={styles.layout}>
                <div className={styles.sidebar}>
                    <Link to={paths.brands} className={styles.sidebar__logo}>
                        <img
                            src="/logo.svg"
                            alt="Brand Selfie"
                            className={styles.sidebar__logoImage}
                        />
                    </Link>
                    <div className={styles.sidebar__actions}>
                        <div className={styles.sidebar__action}>
                            <Button type="text" icon={<AccountIcon />} size="large" />
                        </div>
                        <div className={styles.sidebar__action}>
                            <Button type="text" icon={<LogoutIcon />} size="large" />
                        </div>
                    </div>
                </div>
                <main className={styles.main}>
                    <Outlet />
                </main>
            </div>
        </>
    )
}
